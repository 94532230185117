<template>
  <div class="">
    <spring-spinner :animation-duration="1000" :size="30" :color="'#0060ff'" />
  </div>
</template>

<script>
// To use minified css and js files instead of .vue single file components:
// import 'epic-spinners/dist/lib/epic-spinners.min.css'
// import {AtomSpinner} from 'epic-spinners/dist/lib/epic-spinners.min.js'

import { SpringSpinner } from 'epic-spinners'
export default {
  components: {
    SpringSpinner
  }
}
</script>
<style>
.center__content {
  margin: auto;
  vertical-align: middle;
}
</style>
